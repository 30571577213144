'use client';

import { useCallback, useState } from 'react';

export type UseLazyRemoteHookResult<T, TParams = any> = [
  (params: TParams) => Promise<T | null>,
  {
    data: T | null;
    error: Error | null;
    isFetching: boolean;
    isInitialFetch: boolean;
  },
];

export type UseLazyRemoteOptions<T, TParams = any> = {
  initialData?: T;
  reset?: boolean;
  optimistic?: (params: TParams) => T;
  onRequest?: (params: TParams) => void;
  onComplete?: (result: T | null, params: TParams) => void | Promise<void>;
  onError?: (error: Error) => void;
};

export function useLazyRemote<T, TParams = any>(
  fetcher: (...args: TParams[]) => Promise<T | null>,
  options?: UseLazyRemoteOptions<T, TParams>
): UseLazyRemoteHookResult<T, TParams> {
  const [data, setData] = useState<T | null>(options?.initialData ?? null);
  const [error, setError] = useState<Error | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isInitialFetch, setIsInitialFetch] = useState<boolean>(true);
  const fetch = useCallback(
    async (params: TParams) => {
      let previousDataBeforeOptimistic: T | null = null;
      if (options?.optimistic) {
        setData((prev) => {
          previousDataBeforeOptimistic = prev;
          return options.optimistic?.(params) ?? null;
        });
      }
      options?.onRequest?.(params);
      setIsFetching(true);
      setError(null);

      try {
        const result = await fetcher(params);
        setData(result);
        await options?.onComplete?.(result, params);
        setIsFetching(false);
        setIsInitialFetch(false);
        return result;
      } catch (e) {
        if (previousDataBeforeOptimistic) {
          setData(previousDataBeforeOptimistic);
        }

        options?.onError?.(e as Error);
        setIsFetching(false);
        setIsInitialFetch(false);
        setError(e as Error);
      }

      return null;
    },
    [fetcher, options]
  );

  return [
    fetch,
    {
      data,
      error,
      isFetching,
      isInitialFetch,
    },
  ];
}
